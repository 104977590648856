.StudentTableRandom{
    width: 100%; 
    box-sizing: auto !important;
    table-layout: fixed;
}
.StudentTableRandom tr{
    width: 100%;
}
.StudentTableRandom tr td{
    height: 5vh;
    width: auto;
}


.StudentTableRandom .Tisch{
    width: 100%;
    height: 100%;
    padding: 0px;
    font-weight: 300;
}



.StudentTableRandom td:empty::after{
content: "\00a0";
}

@media only screen and (max-width: 951px){
    .StudentTableRandom{
        display: none;
    }
  
}


