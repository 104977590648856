.Feld {
    min-width: 80px;
    min-height: 60px;
    transition: ease-in-out .4s;
    border-radius: 4px;
}

.Feld:hover{
    background-color: rgb(235 235 235 / 4%);
}

td {
    width: 80px;
    height: 60px;
    padding: 0px;
    margin: 0px;
    font-size: 9pt;
}

table{
    width: 100%;
    border-radius: 5px;
    border: solid 1px rgb(0 0 0 / 4%);
    padding: 10px;
}

.Tisch{
    padding: 5px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: bold;
    transition: ease .5s;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #E1FAEB;
    border-radius: 5px;
    color: #00B96B;
    /* border: 1px solid rgb(0 0 0 / 4%); */
}

.Tisch:hover{
    background-color: rgb(176, 206, 230);
}



/* For the Page just to Display (not to Interact with) */

.smallCustomTable{
    width: 100% !important;
    height: 80vh !important;
    overflow: hidden;
}
.smallCustomTable .Feld{
    width: auto;
    height: 40px;
}

.smallCustomTable .Tisch{
    padding: 5px;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: ease .5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #E1FAEB;
    border-radius: 5px;
    color: #a4a4a4;
}