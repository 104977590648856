.icon-wrapper {
    position: relative;
    padding: 0px 30px;
  }
  
  .icon-wrapper .ant-typography {
    position: absolute;
    top: -2px;
    width: 20px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
  }
  
  .icon-wrapper .icon-wrapper-active {
    color: rgba(0, 0, 0, 0.45);
  }
  
  .icon-wrapper .ant-typography:first-child {
    left: 0;
  }
  
  .icon-wrapper .ant-typography:last-child {
    right: 0;
  }